import { apiClient } from '~services/apiClient'
import esiCarrierService from '~services/esiCarrierService'
import { SignupMachineError } from '~services/signupMachine/signup-machine-error'

import { baseCompanyConfigs } from './companies'
import { Company } from './schema'

const esiCompanyId = 27

interface CompanyService {
  getById(id: number): Promise<Company | undefined>
}

type BEClientConfig = Omit<
  Company,
  'enabled' | 'gbEthnicity' | 'requireSouthAfricaIDNumber'
> & {
  additionalEligibility: {
    ethnicity?: boolean
    requireSouthAfricaIDNumber?: boolean
  }
}

const getHardcodedCompanyConfig = (id: number): Company | undefined => {
  return baseCompanyConfigs.find((c) => c.enabled && c.id === id)
}

const companyService: CompanyService = {
  async getById(id) {
    const getCompanyConfigUsingEndpoint = async (
      id: number
    ): Promise<Company | undefined> => {
      let client: BEClientConfig | undefined
      try {
        const { data } = await apiClient.get<{ client?: BEClientConfig }>(
          `/client-registration-config/${encodeURIComponent(id)}`,
          {
            validateStatus: (status) => status === 200 || status === 404,
          }
        )
        client = data?.client
      } catch (err) {
        return Promise.reject({
          message: SignupMachineError.InternalError,
        })
      }
      if (!client) {
        return undefined
      }
      const { additionalEligibility, ...clientProperties } = client
      return {
        ...clientProperties,
        enabled: true,
        gbEthnicity: additionalEligibility.ethnicity ?? false,
        requireSouthAfricaIDNumber:
          additionalEligibility.requireSouthAfricaIDNumber ?? false,
      }
    }

    const isCompanyConfigEndpointFeatureSwitch =
      import.meta.env.VITE_ENABLE_COMPANY_CONFIG_ENDPOINT === 'true'

    const getCompanyConfig = async (
      id: number
    ): Promise<Company | undefined> => {
      if (isCompanyConfigEndpointFeatureSwitch) {
        return await getCompanyConfigUsingEndpoint(id)
      } else {
        return getHardcodedCompanyConfig(id)
      }
    }

    const company = await getCompanyConfig(id)

    if (!company) {
      // For ESI sub-companies, we need to return the ESI company config.
      // TODO move this logic to BE along with moving the company config to BE
      const esiCompany = await getCompanyConfig(esiCompanyId)

      if (esiCompany) {
        const esiCarriers = await esiCarrierService.fetchEsiCarriers()
        const esiCarrier = esiCarriers.find((c) => c.companyId === id)
        if (esiCarrier) {
          return {
            ...esiCompany,
            subCompanyId: esiCarrier.companyId,
          }
        }
      }
    }
    return company
  },
}

export default Object.freeze(companyService)
export type { Company }
