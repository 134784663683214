export class EmailPhoneValidationError {
  emailInUse: boolean
  emailInvalid: boolean
  phoneInUse: boolean
  phoneInvalid: boolean

  constructor(errors: {
    emailInUse?: boolean
    emailInvalid?: boolean
    phoneInUse?: boolean
    phoneInvalid?: boolean
  }) {
    this.emailInUse = errors.emailInUse || false
    this.emailInvalid = errors.emailInvalid || false
    this.phoneInUse = errors.phoneInUse || false
    this.phoneInvalid = errors.phoneInvalid || false
  }
}
