import { apiClient } from '~services/apiClient'
import { SignupMachineError } from '~services/signupMachine/signup-machine-error'

import esiCarrierConfig from './esiCarriers.yaml'
import { Carrier } from './schema'

const allCarriers: Carrier[] = esiCarrierConfig
const enabledCarriers: Carrier[] = allCarriers.filter(
  (carrier) => carrier.enabled
)

interface SubCompany {
  id: number
  companyName: string
}

interface FetchSubclientsResponse {
  success: boolean
  data: SubCompany[]
}

interface EsiCarrierService {
  fetchEsiCarriers(): Promise<Carrier[]>
}

const esiCarrierService: EsiCarrierService = {
  async fetchEsiCarriers(): Promise<Carrier[]> {
    const isEsiSubcompanyEndpointFeatureSwitch =
      import.meta.env.VITE_ENABLE_SUBCOMPANY_ENDPOINT === 'true'

    if (!isEsiSubcompanyEndpointFeatureSwitch) {
      return enabledCarriers
    }

    const url = '/b2b-user-onboarding/partners/27/clients'
    let subCompanies: SubCompany[] | undefined
    try {
      const {
        data: { data },
      } = await apiClient.get<FetchSubclientsResponse>(url)
      subCompanies = data
    } catch (error) {
      return Promise.reject({
        message: SignupMachineError.InternalError,
      })
    }

    return subCompanies.map((subCompany) => ({
      companyId: subCompany.id,
      name: subCompany.companyName,
      // BE returns only enabled carriers,
      // the enabled field can be removed later,
      // along with VITE_ENABLE_SUBCOMPANY_ENDPOINT feature flag
      enabled: true,
    }))
  },
}

export default Object.freeze(esiCarrierService)
export type { Carrier }
